/*
 * @Author: xz
 * @Date: 2020-09-15 09:30:33
 * @LastEditors: chenggui 1028329845@qq.com
 * @LastEditTime: 2022-11-19 15:23:29
 * @FilePath: \my_homepage\my_homepage\src\router\index.js
 * @Description: 路由配置
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/message',
    name: 'Message',
    // lazyload
    component: () => import('../views/Message.vue')
  },
  {
    path: '/test',
    name: 'Test',
    // lazyload
    component: () => import('../views/Test.vue')
  },
  {
    path: '/test1',
    name: 'Test1',
    // lazyload
    component: () => import('../views/Test1.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
