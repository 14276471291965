/*
 * @Author: xz
 * @Date: 2020-09-15 09:30:33
 * @LastEditors: chenggui 1028329845@qq.com
 * @LastEditTime: 2022-11-19 09:47:58
 * @FilePath: \my_homepage\my_homepage\src\main.js
 * @Description: 项目配置
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/reset.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import animated from 'animate.css'
import './assets/font/font.css'

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(animated)

Vue.config.productionTip = false

// axios.defaults.baseURL = 'http://api.ccghzx.local/www/'
axios.defaults.baseURL = 'https://api.ccghzx.com/www/'
// axios.defaults.baseURL = 'https://api.ccghzx.com/v1/';
Vue.prototype.$ajax = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
