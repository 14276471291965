<!--
 * @Author: xz
 * @Date: 2020-09-15 09:30:33
 * @LastEditors: chenggui 1028329845@qq.com
 * @LastEditTime: 2022-11-21 14:01:33
 * @FilePath: \my_homepage\src\views\Home.vue
 * @Description: 首页
-->
<template>
    <div>
        <loading v-if="!hideLoading" :loading="loading" />
        <div class="login-container">
            <div id="login-three-container" />
            <div v-if="!loading" class="login-plane">
                <el-button type="primary" plain round class="message_btn" @click="$router.push('/message')">给我留言</el-button>

                <el-tooltip class="item" effect="dark" content="页面导航" placement="bottom">
                    <div class="nav_btn" @click="showNav = !showNav">
                        <i v-if="showNav" class="el-icon-close" />
                        <i v-else class="el-icon-more" />
                    </div>
                </el-tooltip>

                <div class="nav_list" :style="showNav ? '' : 'width:0'">
                    <el-menu :default-active="activePage">
                        <el-menu-item index="0" @click="switchToPage(0)">
                            <span class="iconfont nav_list-icon">&#xe56a;</span>
                            <span slot="title">首页</span>
                        </el-menu-item>
                        <el-menu-item index="1" @click="switchToPage(1)">
                            <span class="iconfont nav_list-icon">&#xe631;</span>
                            <span slot="title">关于我</span>
                        </el-menu-item>
                        <el-menu-item index="2" @click="switchToPage(2)">
                            <span class="iconfont nav_list-icon">&#xe617;</span>
                            <span slot="title">对于工作</span>
                        </el-menu-item>
                        <el-menu-item index="3" @click="switchToPage(3)">
                            <span class="iconfont nav_list-icon">&#xe6a1;</span>
                            <span slot="title">对于生活</span>
                        </el-menu-item>
                        <el-menu-item index="4" @click="switchToPage(4)">
                            <span class="iconfont nav_list-icon">&#xe607;</span>
                            <span slot="title">联系我</span>
                        </el-menu-item>
                    </el-menu>
                </div>

                <swiper id="swiperBox" ref="mySwiper" :options="swiperOption">
                    <swiper-slide class="swiper-slide">
                        <div class="page1">
                            <div class="wrap">
                                <div class="main">
                                    <h1 class="main-title">Hi, I'm ccg</h1>
                                    <p>你好，我叫陈成贵</p>
                                    <p>很高兴能在茫茫人海中遇到你</p>
                                    <p>来都来了</p>
                                    <p>不如...</p>
                                    <p>来互相认识一下吧？</p>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide">
                        <div class="page2">
                            <div class="wrap">
                                <img class="avatar" src="http://image.ccghzx.com/profile/images/avatar.jpg" alt>
                                <div :class="scrollToP2 ? 'text fadeIn' : 'text'">
                                    <p>幸会幸会！</p>
                                    <p>陈成贵是我的本名，92年11月..</p>
                                    <p>我是一名后端兼前端开发工程师，坐标福州</p>
                                    <p>闲暇时间会宅在家里，标准的宅男，偶尔也会出门溜达</p>
                                    <p>工作中比较认真，生活中比较随性</p>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide">
                        <div class="page3">
                            <div class="wrap clearfix">
                                <div :class="scrollToP3 ? 'text fadeIn' : 'text'">
                                    <p>后端采用的是php，主要使用的框架有Thinkphp、yii、Hyperf</p>
                                    <p>熟悉使用的框架 Thinkphp、yii、Hyperf 等</p>
                                    <p>这个网站的留言后台使用的是 yii 写的</p>
                                    <p>下面是我的技术栈</p>
                                    <div id="chart" />
                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide">
                        <div class="page4">
                            <div class="wrap clearfix">
                                <div :class="scrollToP4 ? 'text fadeIn' : 'text'">
                                    <h3 class="title">To test the limits and break through</h3>
                                    <p>这是冰雪奇缘主题曲 Let it go 里的一句歌词</p>
                                    <p>同时也是我对生活的态度</p>
                                    <p>我是一个闲不下来的人</p>
                                    <p>只有在不断的test the limits的过程中</p>
                                    <p>才会进步，最终break through</p>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide">
                        <div class="page5">
                            <div class="wrap clearfix">
                                <div :class="scrollToP5 ? 'text fadeIn' : 'text'">
                                    <h3 class="title">Thank for watch</h3>
                                    <p>如需联系我，可通过如下方式</p>
                                    <p>或者左下角给我留言</p>
                                    <div class="btn_list">
                                        <div class="btn">
                                            <img class="icon" src="http://image.ccghzx.com/profile/images/mail.png" alt>
                                            <div class="tip">
                                                1028329845@qq.com
                                                <div class="arrow" />
                                            </div>
                                        </div>
                                        <div class="btn">
                                            <img class="icon" src="http://image.ccghzx.com/profile/images/wechat.png" alt>
                                            <div class="tip">
                                                <img src="http://image.ccghzx.com/profile/images/qrcode.png" alt>
                                                <div class="arrow" />
                                            </div>
                                        </div>
                                        <!-- <a target="_blank" href="https://github.com/xxiizz" class="btn">
                                            <img class="icon" src="http://image.ccghzx.com/profile/images/github.png" alt>
                                            <div class="tip">
                                                github
                                                <div class="arrow" />
                                            </div>
                                        </a> -->
                                    </div>
                                </div>
                                <div class="copyright">
                                    <a target="_blank" href="https://beian.miit.gov.cn/">闽ICP备19023966号</a>
                                    <!-- <div style="width: 300px; margin: 0 auto">
                                        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=19023966" style="display: inline-block;text-decoration: none;height: 20px;line-height: 20px;">
                                            <img src="http://image.ccghzx.com/profile/images/beian.png" style="float: left">
                                            <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393;">
                                                闽ICP备19023966号
                                            </p>
                                        </a>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <div slot="pagination" class="swiper-pagination" />
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import loading from '../components/loading'

import * as THREE from 'three' // 导入整个 three.js核心库

// import { GUI } from 'three/examples/jsm/libs/dat.gui.module'
import Stats from 'three/examples/jsm/libs/stats.module.js'
import _ from 'lodash'
// import { ElMessage } from 'element-plus'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default {
  name: 'Home',
  components: {
    loading
  },
  data () {
    return {
      scrollToP5: false,
      scrollToP4: false,
      scrollToP3: false,
      scrollToP2: false,
      count: 0,
      hideLoading: false,
      loading: true,
      activePage: '0',
      showNav: false,
      swiperOption: {
        speed: 600,
        notNextTick: true, // notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: 'vertical', // 垂直方向移动
        autoHeight: true, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, // 设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, // 开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, // 同上
        height: window.innerHeight, // 高度设置，占满设备高度
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletElement: 'li',
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        },

        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        on: {
          // 监听滑动切换事件，返回swiper对象
          slideChange: () => {
            const swiper = this.$refs.mySwiper.swiper
            this.activePage = swiper.activeIndex.toString()
            if (swiper.activeIndex === 1) {
              this.scrollToP2 = true
            }
            if (swiper.activeIndex === 2) {
              this.drawChart()
              this.scrollToP3 = true
            }
            if (swiper.activeIndex === 3) {
              this.scrollToP4 = true
            }
            if (swiper.activeIndex === 4) {
              this.scrollToP5 = true
            }
            console.log(swiper.activeIndex) // 滑动打印当前索引
          }
        }
      },

      // 容器
      container: null,
      // 声明视口宽度
      width: null,
      // 声明视口高度
      height: null,
      // 盒模型的深度
      depth: 1400,
      // 声明场景
      scene: null,
      // 声明球组
      Sphere_Group: null,
      // 声明球体几何
      sphereGeometry: null,
      // 声明完整球
      sphere: null,
      // 声明相机
      camera: null,
      // 声明相机在z轴的位置
      zAxisNumber: null,
      // 声明相机目标点
      cameraTarget: new THREE.Vector3(0, 0, 0),
      // 声明点材质
      materials: [],
      // 声明点的参数
      parameters: null,
      // 声明点在z轴上移动的进度
      zprogress: null,
      // 声明同上（第二个几何点）
      zprogress_second: null,
      // 声明粒子1
      particles_first: [],
      // 声明粒子1
      particles_second: [],
      // 声明粒子1的初始化位置
      particles_init_position: null,
      // 声明流动的云对象1（包含路径、云实例）
      cloudParameter_first: null,
      // 声明流动的云对象2（包含路径、云实例）
      cloudParameter_second: null,
      // 声明云流动的渲染函数1
      renderCloudMove_first: null,
      // 声明云流动的渲染函数1
      renderCloudMove_second: null,
      // 声明性能监控
      stats: new Stats(),
      // 声明渲染器
      renderer: null,
      // 声明调试工具
      // gui: new GUI(),
      // 表单对象
      formRef: null,
      // 其他状态
      state: {
        codeSrc: '',
        codetoken: ''
      }
    }
  },
  computed: {
    swiper () {
      // 获取swiper实例
      return this.$refs.mySwiper.swiper
    }
  },
  created () {
    // 预加载图片
    this.preload()
    // 统计新访客
    this.$ajax.post('message/new_visitor').then((res) => {
      console.log(res)
    })
  },
  mounted () {
    this.container = document.getElementById('login-three-container')
    this.width = this.container.clientWidth
    this.height = this.container.clientHeight
    this.initScene()
    this.initSceneBg()
    this.initCamera()
    this.initLight()
    this.initSphereModal()
    this.initSphereGroup()
    this.particles_init_position = -this.zAxisNumber - this.depth / 2
    this.zprogress = this.particles_init_position
    this.zprogress_second = this.particles_init_position * 2
    this.particles_first = this.initSceneStar(this.particles_init_position)
    this.particles_second = this.initSceneStar(this.zprogress_second)
    this.cloudParameter_first = this.initTubeRoute(
      [
        new THREE.Vector3(-this.width / 10, 0, -this.depth / 2),
        new THREE.Vector3(-this.width / 4, this.height / 8, 0),
        new THREE.Vector3(-this.width / 4, 0, this.zAxisNumber)
      ],
      400,
      200
    )
    this.cloudParameter_second = this.initTubeRoute(
      [
        new THREE.Vector3(this.width / 8, this.height / 8, -this.depth / 2),
        new THREE.Vector3(this.width / 8, this.height / 8, this.zAxisNumber)
      ],
      200,
      100
    )
    this.initRenderer()
    // 控制器必须放在renderer函数后面
    this.initOrbitControls()
    this.animate()
    // initGUI()
    // const axesHelper = new THREE.AxesHelper(2000)
    // this.scene.add(axesHelper)
  },
  methods: {
    switchToPage (page) {
      this.swiper.slideTo(page, 1000, false)
    },
    preload: function () {
      // 背景图预加载
      const imgs = [
        'http://image.ccghzx.com/profile/12.jpg',
        'http://image.ccghzx.com/profile/12.jpg',
        'http://image.ccghzx.com/profile/12.jpg',
        'http://image.ccghzx.com/profile/12.jpg',
        'http://image.ccghzx.com/profile/12.jpg'
      ]
      for (const img of imgs) {
        console.log(222)
        const image = new Image()
        image.onload = () => {
          console.log(34343)
          this.count++
          if (this.count === imgs.length) {
            console.log(12121)
            this.loading = false
            setTimeout(() => {
              this.hideLoading = true
            }, 1000)
          }
        }
        image.src = img
      }
    },

    drawChart () {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('chart'))
      // 绘制图表
      myChart.setOption({
        angleAxis: {
          max: 100,
          startAngle: 30,
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          type: 'category',
          data: ['掌握程度'],
          z: 10
        },
        polar: {},
        series: [
          {
            type: 'bar',
            data: [99],
            coordinateSystem: 'polar',
            name: 'php',
            color: '#61dafb',
            itemStyle: {
              borderColor: 'white',
              borderWidth: 1
            }
          },
          {
            type: 'bar',
            data: [90],
            coordinateSystem: 'polar',
            name: 'html',
            color: '#e1480e',
            itemStyle: {
              borderColor: 'white',
              borderWidth: 1
            }
          },
          {
            type: 'bar',
            data: [90],
            coordinateSystem: 'polar',
            name: 'css',
            color: '#3d97e2',
            itemStyle: {
              borderColor: 'white',
              borderWidth: 1
            }
          },
          {
            type: 'bar',
            data: [90],
            coordinateSystem: 'polar',
            name: 'javaScript',
            color: '#fcd400',
            itemStyle: {
              borderColor: 'white',
              borderWidth: 1
            }
          },
          {
            type: 'bar',
            data: [85],
            coordinateSystem: 'polar',
            name: 'Vue',
            color: '#41b883',
            itemStyle: {
              borderColor: 'white',
              borderWidth: 1
            }
          },
          {
            type: 'bar',
            data: [60],
            coordinateSystem: 'polar',
            name: '小程序',
            color: '#07c160',
            itemStyle: {
              borderColor: 'white',
              borderWidth: 1
            }
          }
          // {
          //     type: "bar",
          //     data: [70],
          //     coordinateSystem: "polar",
          //     name: "Node",
          //     color: "#8cc84c",
          //     itemStyle: {
          //         borderColor: "white",
          //         borderWidth: 1,
          //     },
          // },
        ],
        legend: {
          show: true,
          data: ['php', 'html', 'css', 'javaScript', 'Vue', '小程序', 'Node']
        }
      })
    },

    // 初始化场景
    initScene () {
      this.scene = new THREE.Scene()
      // 在场景中添加雾的效果，Fog参数分别代表‘雾的颜色’、‘开始雾化的视线距离’、刚好雾化至看不见的视线距离’
      this.scene.fog = new THREE.Fog(0x000000, 0, 10000)
    },
    // 初始化背景（盒模型背景，视角在盒子里面，看到的是盒子内部）
    initSceneBg () {
      new THREE.TextureLoader().load(require('@/assets/images/sky.png'), (texture) => {
        const geometry = new THREE.BoxGeometry(this.width, this.height, this.depth) // 创建一个球形几何体 SphereGeometry
        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide }) // 创建基础为网格基础材料
        const mesh = new THREE.Mesh(geometry, material)
        this.scene.add(mesh)
      })
    },
    // 初始化轨道控制器
    initOrbitControls () {
      const controls = new OrbitControls(this.camera, this.renderer.domElement)
      // enabled设置为true是可以使用鼠标控制视角
      controls.enabled = false
      controls.maxPolarAngle = 10
      controls.update()
    },
    // 初始化相机
    initCamera () {
      /**
           * 方式2:固定视域角度，求需要多少距离才能满足完整的视野画面
           * 15度等于(Math.PI / 12)
           */
      const fov = 15
      const distance = this.width / 2 / Math.tan(Math.PI / 12)
      this.zAxisNumber = Math.floor(distance - this.depth / 2)
      this.camera = new THREE.PerspectiveCamera(fov, this.width / this.height, 1, 30000)

      /**
           * 这里给z轴的距离加了100，原因是做调整，使得视域更完整
           * 这么做并不代表前面计算错误了，根据前面的计算值并不能很完整的看到
           * 至于原因，我想大概就类似于0.1+0.2不等于0.3吧
           * 所以我自作主张地加了100的值做调整（但是不建议，因为当屏幕足够宽时候会看到边缘）
           */
      // camera.position.set(0, 0, zAxisNumber + 100)
      this.camera.position.set(0, 0, this.zAxisNumber)
      this.camera.lookAt(this.cameraTarget)
      // const helper = new THREE.CameraHelper(camera)
      // helper.update()
      // scene.add(helper)
    },
    // 光源
    initLight () {
      const ambientLight = new THREE.AmbientLight(0xffffff, 1)
      // 右下角点光源
      const light_rightBottom = new THREE.PointLight(0x0655fd, 5, 0)
      light_rightBottom.position.set(0, 100, -200)
      this.scene.add(light_rightBottom)
      this.scene.add(ambientLight)
    },
    // 初始化球体模型
    initSphereModal () {
      // 材质
      const material = new THREE.MeshPhongMaterial()
      material.map = new THREE.TextureLoader().load(require('@/assets/images/earth_bg.png'))
      material.blendDstAlpha = 1
      // 几何体
      this.sphereGeometry = new THREE.SphereGeometry(50, 64, 32)
      // 模型
      this.sphere = new THREE.Mesh(this.sphereGeometry, material)
    },
    // 初始化组 --- 球体
    initSphereGroup () {
      this.Sphere_Group = new THREE.Group()
      this.Sphere_Group.add(this.sphere)
      this.Sphere_Group.position.x = -400
      this.Sphere_Group.position.y = 200
      this.Sphere_Group.position.z = -200
      this.scene.add(this.Sphere_Group)
    },
    // 初始化流动路径
    initTubeRoute (route, geometryWidth, geometryHeigh) {
      const curve = new THREE.CatmullRomCurve3(route, false)
      const tubeGeometry = new THREE.TubeGeometry(curve, 100, 2, 50, false)
      const tubeMaterial = new THREE.MeshBasicMaterial({
        // color: '0x4488ff',
        opacity: 0,
        transparent: true
      })
      const tube = new THREE.Mesh(tubeGeometry, tubeMaterial)
      this.scene.add(tube)
      const clondGeometry = new THREE.PlaneGeometry(geometryWidth, geometryHeigh)
      const textureLoader = new THREE.TextureLoader()
      const cloudTexture = textureLoader.load(require('@/assets/images/cloud.png'))
      const clondMaterial = new THREE.MeshBasicMaterial({
        map: cloudTexture,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true
      })
      const cloud = new THREE.Mesh(clondGeometry, clondMaterial)
      this.scene.add(cloud)
      return {
        cloud,
        curve
      }
    },
    // 初始化场景星星效果
    initSceneStar (initZposition) {
      const geometry = new THREE.BufferGeometry()
      const vertices = []
      const pointsGeometry = []
      const textureLoader = new THREE.TextureLoader()
      const sprite1 = textureLoader.load(require('@/assets/images/starflake1.png'))
      const sprite2 = textureLoader.load(require('@/assets/images/starflake2.png'))
      this.parameters = [
        [[0.6, 100, 0.75], sprite1, 50],
        [[0, 0, 1], sprite2, 20]
      ]
      // 初始化500个节点
      for (let i = 0; i < 500; i++) {
        /**
                 * const x = Math.random() * 2 * width - width
                 * 等价
                 * THREE.MathUtils.randFloatSpread(width)
                 */
        const x = THREE.MathUtils.randFloatSpread(this.width)
        const y = _.random(0, this.height / 2)
        const z = _.random(-this.depth / 2, this.zAxisNumber)
        vertices.push(x, y, z)
      }
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
      // 创建2种不同的材质的节点（500 * 2）
      for (let i = 0; i < this.parameters.length; i++) {
        const color = this.parameters[i][0]
        const sprite = this.parameters[i][1]
        const size = this.parameters[i][2]
        this.materials[i] = new THREE.PointsMaterial({
          size,
          map: sprite,
          blending: THREE.AdditiveBlending,
          depthTest: true,
          transparent: true
        })
        this.materials[i].color.setHSL(color[0], color[1], color[2])
        const particles = new THREE.Points(geometry, this.materials[i])
        particles.rotation.x = Math.random() * 0.2 - 0.15
        particles.rotation.z = Math.random() * 0.2 - 0.15
        particles.rotation.y = Math.random() * 0.2 - 0.15
        particles.position.setZ(initZposition)
        pointsGeometry.push(particles)
        this.scene.add(particles)
      }
      return pointsGeometry
    },
    // 渲染星球的自转
    renderSphereRotate () {
      if (this.sphere) {
        this.Sphere_Group.rotateY(0.001)
      }
    },
    // 渲染星星的运动
    renderStarMove () {
      const time = Date.now() * 0.00005
      this.zprogress += 1
      this.zprogress_second += 1
      if (this.zprogress >= this.zAxisNumber + this.depth / 2) {
        this.zprogress = this.particles_init_position
      } else {
        this.particles_first.forEach((item) => {
          item.position.setZ(this.zprogress)
        })
      }
      if (this.zprogress_second >= this.zAxisNumber + this.depth / 2) {
        this.zprogress_second = this.particles_init_position
      } else {
        this.particles_second.forEach((item) => {
          item.position.setZ(this.zprogress_second)
        })
      }
      for (let i = 0; i < this.materials.length; i++) {
        const color = this.parameters[i][0]
        const h = ((360 * (color[0] + time)) % 360) / 360
        this.materials[i].color.setHSL(color[0], color[1], parseFloat(h.toFixed(2)))
      }
    },
    // 初始化云的运动函数
    initCloudMove (cloudParameter, speed, scaleSpeed = 0.0006, maxScale = 1, startScale = 0) {
      let cloudProgress = 0
      return () => {
        if (startScale < maxScale) {
          startScale += scaleSpeed
          cloudParameter.cloud.scale.setScalar(startScale)
        }
        if (cloudProgress > 1) {
          cloudProgress = 0
          startScale = 0
        } else {
          cloudProgress += speed
          if (cloudParameter.curve) {
            const point = cloudParameter.curve.getPoint(cloudProgress)
            if (point && point.x) {
              cloudParameter.cloud.position.set(point.x, point.y, point.z)
            }
          }
        }
      }
    },
    // 渲染器
    initRenderer () {
      // 开启抗锯齿
      // 在 css 中设置背景色透明显示渐变色
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      // 定义渲染器的尺寸；在这里它会填满整个屏幕
      this.renderer.setSize(this.width, this.height)
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.container.appendChild(this.renderer.domElement)
      // this.container.appendChild(this.stats.dom)
      this.renderCloudMove_first = this.initCloudMove(this.cloudParameter_first, 0.0002)
      this.renderCloudMove_second = this.initCloudMove(this.cloudParameter_second, 0.0008, 0.001)
    },
    // 动画刷新
    animate () {
      requestAnimationFrame(this.animate)
      this.renderSphereRotate()
      this.renderStarMove()
      this.renderCloudMove_first
      this.renderCloudMove_second
      this.renderer.render(this.scene, this.camera)
    }
  }
}
</script>

<style lang='less' scoped>
#swiperBox {
  min-width: 1420px;
}

.fadeIn {
  animation: fadeInUp 1.2s ease 300ms;
  animation-fill-mode: forwards;
}

.page1 {
  // background: url("http://image.ccghzx.com/profile/22.jpg") center center no-repeat;
  // background-size: 100%;
  width: 100%;
  min-width: 1420px;
  height: 100%;

  // @keyframes changeBGimg {
  //     0% {
  //         background: url("http://image.ccghzx.com/profile/12.jpg") center center no-repeat;
  //         background-size: 100%;
  //     }

  //     33% {
  //         background: url("http://image.ccghzx.com/profile/12.jpg") center center no-repeat;
  //         background-size: 100%;
  //     }

  //     66% {
  //         background: url("http://image.ccghzx.com/profile/22.jpg") center center no-repeat;
  //         background-size: 100%;
  //     }

  //     100% {
  //         background: url("http://image.ccghzx.com/profile/12.jpg") center center no-repeat;
  //         background-size: 100%;
  //     }
  // }

  animation: changeBGimg 10s infinite 2.5s;

  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
      padding: 20px;
      opacity: 0;
      width: 600px;
      height: 250px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 50px #111;
      animation: fadeInUp 1.2s ease 1000ms;
      animation-fill-mode: forwards;
      transition: 600ms;
      text-align: center;

      .main-title {
        font-family: netsrak;
        cursor: default;
        transition: 600ms;
      }

      p {
        font-family: youzai;
        font-size: 30px;
        line-height: 42px;
        cursor: default;
        transition: 600ms;
      }
    }

    .main:hover {
      padding: 30px;
      background: rgba(255, 255, 255, 0.8);

      p {
        font-size: 35px;
      }

      .main-title {
        font-size: 35px;
      }
    }
  }
}

.page2 {
  // background: url("http://image.ccghzx.com/profile/22.jpg") center center no-repeat;
  // background-size: 100%;
  min-width: 1420px;
  width: 100%;
  height: 100%;

  .wrap {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: relative;

    .avatar {
      position: absolute;
      width: 200px;
      height: 200px;
      left: 48%;
      top: 15%;
      transform: translate(-50%);
      border-radius: 50%;
      transition: 500ms;
    }

    .avatar:hover {
      box-shadow: 0 0 30px #fff;
    }

    .text {
      opacity: 0;
      position: absolute;
      top: 410px;
      left: 400px;
      color: white;
      font-size: 20px;
      line-height: 45px;
      text-align: center;
    }
  }
}

.page3 {
  // background: url("http://image.ccghzx.com/profile/12.jpg") center center no-repeat;
  // background-size: 100%;
  min-width: 1420px;
  width: 100%;
  height: 100%;

  .wrap {
    width: 1280px;
    margin: 0 auto;
    height: 100%;
    position: relative;

    #chart {
      width: 450px;
      height: 450px;
      margin: 30px auto;
    }

    .text {
      opacity: 0;
      font-size: 24px;
      text-align: center;
      width: 690px;
      padding: 20px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      font-weight: bold;
      transition: 600ms;
      margin: 50px auto;
      color: #444;

      p {
        padding: 5px 0;
      }
    }

    .text:hover {
      color: #111;
      background: rgba(255, 255, 255, 0.7);
    }
  }
}

.page4 {
  // background: url("http://image.ccghzx.com/profile/12.jpg") center center no-repeat;
  // background-size: 100%;
  min-width: 1420px;
  width: 100%;
  height: 100%;

  .wrap {
    width: 1280px;
    height: 100%;
    margin: 0 auto;

    .text {
      margin: 200px auto 0;
      opacity: 0;
      padding: 30px;
      width: 700px;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      border-radius: 20px;
      box-shadow: 0 0 10px white;
      transition: 600ms;

      .title {
        font-size: 36px;
        font-family: netsrak;
        text-align: center;
        margin: 30px 0;
      }

      p {
        font-size: 28px;
        text-align: center;
        letter-spacing: 1px;
      }
    }

    .text:hover {
      padding: 40px;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

.page5 {
  // background: url("http://image.ccghzx.com/profile/12.jpg") center center no-repeat;
  // background-size: 100%;
  min-width: 1420px;
  width: 100%;
  height: 100%;

  .wrap {
    width: 1280px;
    height: 100%;
    position: relative;
    margin: 0 auto;

    .text {
      margin: 200px auto 0;
      opacity: 0;
      padding: 30px;
      color: white;
      text-align: center;

      .title {
        font-family: netsrak;
        font-size: 40px;
        margin-bottom: 20px;
      }

      p {
        font-size: 24px;
      }

      .btn_list {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .btn {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.2);
          position: relative;
          transition: 300ms;

          .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -40px;
            margin-left: -40px;
            transition: 300ms;
            width: 80px;
            height: 80px;
          }

          .tip {
            position: absolute;
            padding: 10px;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 6px);
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.1);
            color: #777;
            opacity: 0;
            transition: 300ms;

            img {
              width: 200px;
              height: 200px;
            }

            .arrow {
              position: absolute;
              left: 50%;
              top: 0;
              transform: translate(-50%, -12px);
              width: 0;
              height: 0;
              border: 6px solid transparent;
              border-bottom-color: rgba(0, 0, 0, 0.1);
            }
          }
        }

        .btn:hover {
          background-color: rgba(0, 0, 0, 0.7);

          .tip {
            opacity: 1;
          }
        }
      }
    }

    .copyright {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%);
      text-align: center;

      .title {
        text-align: center;
        font-size: 18px;
        font-family: netsrak;
        color: #777;
        margin-bottom: 20px;
      }

      a {
        font-size: 14px;
        color: #939393;
        padding: 5px;
      }

      .opensource {
        font-size: 14px;
        color: #939393;
      }
    }
  }
}

.nav_btn {
  position: fixed;
  top: 50px;
  right: 50px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.2);
  background: transparent;
  color: #ddd;
  z-index: 101;
  transition: 0.6s;
}

.nav_btn:hover {
  background: #409eff;
}

.nav_list {
  padding-top: 300px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
  overflow: hidden;
  transition: 0.6s;
  width: 300px;

  .nav_list-icon {
    font-size: 18px;
    display: inline-block;
    width: 30px;
    line-height: 100%;
  }
}

.message_btn {
  position: fixed;
  left: 50px;
  bottom: 50px;
  z-index: 99;
}
</style>

<style lang="less">
@keyframes shadowFadeIn {
  from {
    box-shadow: 0 0 0 0 #409eff;
  }

  to {
    box-shadow: 0 0 0 10px rgba(37, 143, 184, 0);
  }
}

.my-bullet {
  width: 8px;
  height: 8px;
  border: 3px solid #ddd;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  margin: 20px;
}

.my-bullet-active {
  background-color: #409eff;
  animation: shadowFadeIn 1.2s ease infinite;
}

.login-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.login-container #login-three-container {
  width: 100%;
  height: 100%;
}

.login-container .login-plane {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  // background-image: url('~@/assets/images/login_border.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  top: 0;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.login-container .login-ground {
  position: absolute;
  z-index: 9998;
  width: 100%;
  height: 400px;
  background-image: url('~@/assets/images/ground.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  left: 0;
}

.login-plane .login-plane-container {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-color: #007eff2e;
  position: relative;
}

.login-plane-container .login-plane-human {
  position: absolute;
  width: 260px;
  right: -120px;
  top: -100px;
  animation: humanMove 8s linear 0s infinite normal;
}

.login-plane-container .login-plane-title {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 35px;
  color: #fff;
  font-weight: 700;
}

.login-plane-title img {
  width: 50%;
}

.login-plane-title .login-plane-title-line {
  width: 80%;
  position: absolute;
  bottom: 0;
}

.login-plane-container .login-plane-human {
  position: absolute;
  width: 260px;
  right: -120px;
  top: -100px;
  animation: humanMove 8s linear 0s infinite normal;
}

.login-plane-container .login-plane-form {
  padding: 45px 55px;
  box-sizing: border-box;

}

.login-plane-form .login-code-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

}

.login-code-container .login-code {
  cursor: pointer;
  width: 45%;
  height: 40px;
  background-color: #c8c8c8;

}

.login-code img {
  width: 100%;
  height: 100%;
}

@keyframes humanMove {
  0% {
    top: -100px;
  }

  25% {
    top: -120px;
  }

  50% {
    top: -100px;
  }

  75% {
    top: -80px;
  }

  100% {
    background: -100px;
  }
}

.el-input__inner {
  background-color: transparent;
  border: 0.071429rem solid rgba(64, 130, 250, 0.8);
  // color: #ffffff !important;
}
</style>
