/*
 * @Author: chenggui 1028329845@qq.com
 * @Date: 2022-11-18 21:17:46
 * @LastEditors: chenggui 1028329845@qq.com
 * @LastEditTime: 2022-11-19 10:05:29
 * @FilePath: \www\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
